/* eslint react/jsx-no-target-blank: 0 */
import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

import "./NavigationLink.scss";

const NavigationLink = ({ link, activeClassName }) => (
    !link.external ? (
        <Link
            to={link.path}
            activeClassName={activeClassName}>
            {link.name}
        </Link>
    ) : (
        <a
            href={link.path}
            target="_blank"
            rel={cx(`noopener`, {
                'noreferrer': !link.trusted
            })}>
            {link.name}
        </a>
    )
);

export default NavigationLink;
