import React from "react";
import cx from "classnames";

import "./Container.scss";

const Container = ({ children, className, ...props }) => (
    <div {...props} className={cx(className, "container")}>
        {children}
    </div>
);

export default Container;
