import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";

import Header from "../Header/Header";
import "./normalize.scss";
import "fontsource-playfair-display/400.css";
import "fontsource-playfair-display/600.css";
import "fontsource-playfair-display/700.css";
import "fontsource-inter";
import "fontsource-inter/500.css";
import "fontsource-inter/600.css";
import "fontsource-inter/700.css";
import "./Layout.scss";

const transitionDuration = 0.1;
const variants = {
  open: {
    transition: {
      duration: transitionDuration,
    },
  },
  exit: {
    transition: {
      when: "afterChildren",
      duration: transitionDuration,
    },
  },
};

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Add <AnimatePresence initial={false} exitBeforeEnter> around <main> to
  // animate. Props:
  // key={location.pathname}
  // initial="initial"
  // animate="open"
  // exit="exit"
  // variants={variants}
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
    </>
  );
};

export default Layout;
