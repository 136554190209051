import React from 'react';

import Layout from './components/Layout/Layout';

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element, props }) => {
    const layout = props.pageContext.layout;
    if (layout === 'empty') {
        return element;
    } else {
        return (
            <Layout {...props}>{element}</Layout>
        );
    }
};
