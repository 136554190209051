import React from "react";

export default ({ children, ...props }) => {
	const className = children.props.className || ""
	// eslint-disable-next-line no-sparse-arrays
	const matches = className.match(/language-(\w*)/) || [,'text'];

	return (
		<>
			<pre {...props}>
				{children}
			</pre>
		</>
	);
};
