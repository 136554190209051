import React from 'react';
import { MDXProvider } from '@mdx-js/react';

import pre from './mdx/pre';
import Cols from './mdx/Cols';

const components = {
	pre,
	Cols
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
