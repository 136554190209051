import React, { useEffect, useState, useLayoutEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import Container from "../Container/Container";
import NavigationLink from "../NavigationLink/NavigationLink";

import "./HamburgerMenu.scss";

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const data = useStaticQuery(graphql`
        query {
          allNavigationYaml {
            nodes {
              id
              name
              path
              external
              #trusted
            }
          }
        }
    `);


    useLayoutEffect(() => {
        document.documentElement.style.overflow = isOpen ? 'hidden' : '';
        return () => {
            document.documentElement.style.overflow = '';
        };
    }, [isOpen]);

    const location = useLocation();

    // This closes the hamburger when location changes
    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    return (
        <div className="hamburger">
            <button
                onClick={() => setIsOpen(!isOpen)}
                aria-label="Open Navigation Menu"
                className={cx("hamburger__box", {
                    "hamburger__box--open": isOpen
                })}>
                <span className="hamburger__part hamburger__part--top"></span>
                <span className="hamburger__part hamburger__part--middle"></span>
                <span className="hamburger__part hamburger__part--bottom"></span>
            </button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        variants={{
                            exit: {
                                opacity: 0.8,
                                x: '100vw',
                                transition: {
                                    delay: 0.18,
                                    type: "spring",
                                    damping: 50,
                                    stiffness: 200,
                                    staggerChildren: 0.02,
                                    staggerDirection: -1
                                }
                            },
                            enter: {
                                opacity: 1,
                                x: 0,
                                transition: {
                                    delayChildren: 0.2,
                                    type: "spring",
                                    damping: 50,
                                    stiffness: 200,
                                    staggerChildren: 0.05
                                }
                            }
                        }}
                        key="hamburger__menu"
                        initial="exit"
                        animate="enter"
                        exit="exit"
                        className="hamburger__menu">
                        <Container>
                            <nav>
                                <motion.ul>
                                    {data.allNavigationYaml.nodes.map(link => (
                                        <motion.li
                                            variants={{
                                                exit: {
                                                    opacity: 0,
                                                    y: 50,
                                                    velocity: 10
                                                },
                                                enter: {
                                                    opacity: 1,
                                                    y: 0
                                                }
                                            }}
                                            key={link.id}>
                                            <NavigationLink link={link} activeClassName="hamburger__active-link" />
                                        </motion.li>
                                    ))}
                                </motion.ul>
                            </nav>
                        </Container>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default HamburgerMenu;
