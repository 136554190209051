import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import Container from "../Container/Container";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import NavigationLink from "../NavigationLink/NavigationLink";

import "./Header.scss";

const Header = ({ siteTitle }) => {
    const data = useStaticQuery(graphql`
        query {
          allNavigationYaml {
            nodes {
              id
              name
              path
              external
              #trusted
            }
          }
        }
    `);

    return (
        <header>
            <Container className="header">
                <div>
                    <Link className="header__tag" to="/">{ siteTitle[0] }</Link>
                </div>
                <div className="header__spacer"></div>
                <nav>
                    <ul>
                        {data.allNavigationYaml.nodes.map(link => (
                            <li key={link.id}>
                                <NavigationLink link={link} activeClassName="header__link--active" />
                            </li>
                        ))}
                    </ul>
                </nav>
                <HamburgerMenu/>
            </Container>
        </header>
    );
}

export default Header
