import React from "react";

function Cols({ children }) {
    return (
        <div className="md-cols">{children}</div>
    )
};

Cols.Item = ({ children }) => (
    <div className="md-cols__child">
        {children}
    </div>
);


export default Cols;
